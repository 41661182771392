import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@garmin-avcloud/avcloud-web-utils';
import { featureGuard } from '@garmin-avcloud/avcloud-web-utils/feature';
import { environment } from 'src/environments/environment';
import { EmptyLayoutComponent } from './core/components/layouts/empty-layout/empty-layout.component';
import { NotAuthorizedComponent } from './core/components/layouts/not-authorized.component';
import { StandardLayoutComponent } from './core/components/layouts/standard-layout/standard-layout.component';
import { LogoutComponent } from './core/components/pages/logout/logout.component';
import { orgRouteGuard } from './features/orgs/org.guard';
import { DownForMaintenanceComponent } from './shared/components/pages/down-for-maintenance/down-for-maintenance.component';
import { FullPageLoaderComponent } from './shared/components/pages/full-page-loader/full-page-loader.component';
import { FeatureFlag } from './shared/enums/feature-flag.enum';

const routes: Routes = [
  {
    path: '',
    component: StandardLayoutComponent,
    children: [
      {
        path: '',
        resolve: { url: () => window.location.replace(environment.flyLegacyUrl) },
        component: FullPageLoaderComponent
      },
      {
        path: 'aircraft',
        component: EmptyLayoutComponent,
        title: 'Aircraft',
        canActivate: [AuthGuard],
        children: [
          {
            loadChildren: () => import('./features/aircraft/aircraft.module').then((m) => m.AircraftModule),
            path: ''
          }
        ]
      },
      {
        path: 'aircraft-logbook',
        title: 'Aircraft Logbook',
        canActivate: [AuthGuard, featureGuard],
        data: {
          feature: FeatureFlag.AVC_FLY_ENABLE_AIRCRAFT_LOGBOOK
        },
        loadChildren: () => import('./features/aircraft-logbook/logbook.routes').then((r) => r.generateLogbookRoutes)
      },
      {
        path: 'aviation-support',
        component: EmptyLayoutComponent,
        title: 'Aviation Product Support',
        children: [
          {
            loadChildren: () => import('./features/support/support.module').then((m) => m.SupportModule),
            path: ''
          }
        ]
      },
      {
        path: 'dashboard',
        component: EmptyLayoutComponent,
        canActivate: [AuthGuard],
        title: 'Dashboard'
      },
      {
        path: 'purchasing',
        component: EmptyLayoutComponent,
        canActivate: [AuthGuard],
        title: 'AVDB Auto Renewal',
        children: [
          {
            loadChildren: () => import('./features/purchasing/purchasing.module').then((m) => m.PurchasingModule),
            path: ''
          }
        ]
      },
      {
        path: 'select-locale',
        component: EmptyLayoutComponent,
        title: 'Locale Selection',
        children: [
          {
            loadChildren: () => import('./features/locale/locale.module').then((m) => m.LocaleModule),
            path: ''
          }
        ]
      },
      {
        path: 'select-profile',
        component: EmptyLayoutComponent,
        canActivate: [AuthGuard, featureGuard],
        title: 'Profile Selection',
        data: {
          feature: FeatureFlag.ORGS_ROLES
        },
        children: [
          {
            loadChildren: () =>
              import('./features/profile/profile-selection.routes').then((m) => m.PROFILE_SELECTION_ROUTES),
            path: ''
          }
        ]
      },
      {
        path: 'subscriptions',
        component: EmptyLayoutComponent,
        canActivate: [featureGuard],
        data: {
          feature: {
            name: FeatureFlag.HIDE_SUBSCRIPTION_MANAGEMENT,
            negateFlag: true,
            redirectRoute: 'maintenance'
          }
        },
        title: 'Subscription Management',
        children: [
          {
            loadChildren: () =>
              import('./features/subscription-management/subscription-management.module').then(
                (m) => m.SubscriptionManagementModule
              ),
            path: ''
          }
        ]
      },
      {
        path: 'not-found',
        component: EmptyLayoutComponent,
        title: 'Not Found',
        children: [
          {
            loadChildren: () => import('./features/not-found/not-found.module').then((m) => m.NotFoundModule),
            path: ''
          }
        ]
      },
      {
        path: 'denied',
        component: EmptyLayoutComponent,
        title: 'Permission Denied',
        loadChildren: () => import('./features/denied/denied.routes').then((denied) => denied.routes)
      },
      {
        path: 'home',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: 'org/membership-invites',
        title: 'Membership Invite',
        component: EmptyLayoutComponent,
        canActivate: [AuthGuard, featureGuard],
        data: {
          feature: FeatureFlag.ORGS_ROLES
        },
        loadChildren: () => import('./features/orgs/accept-invitation/invite.routes').then((r) => r.ROUTES)
      },
      {
        path: 'org',
        component: EmptyLayoutComponent,
        canActivate: [AuthGuard, featureGuard, orgRouteGuard],
        title: 'Organization',
        data: {
          feature: FeatureFlag.ORGS_ROLES
        },
        loadChildren: () => import('./features/orgs/orgs.routes').then((r) => r.generateOrgRoutes())
      },
      {
        path: 'gifd',
        canActivate: [AuthGuard, featureGuard],
        data: {
          feature: FeatureFlag.HYDRA
        },
        loadChildren: () => import('./features/gifd/gifd.routes').then((gifd) => gifd.routes)
      },
      {
        path: 'maintenance',
        component: DownForMaintenanceComponent
      }
    ]
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'webview',
    children: [
      {
        loadChildren: () => import('./features/webview/webview.module').then((m) => m.WebviewModule),
        path: ''
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
